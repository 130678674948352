import React, { lazy, Suspense } from "react";
import ReactDOM from "react-dom";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "./scss/index.css";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import App from "./App";

import HttpService from "./services/HttpService";
import UserService from "./services/UserService";
import GuardedRoute from "./GuardedRoute";
import AuthenticatedRoute from "./services/AuthenticatedRoute";
import LoaderBar from "./components/shared/LoaderBar";
import OffsetAccountSettings from "./components/connect/OffsetAccountSettings";

const Home = lazy(() => import("./components/connect/ConnectHome"));
const JoinForm = lazy(() => import("./components/shared/reset"));
const NotFoundPage = lazy(() => import("./components/shared/NotFoundPage"));
const NotAuthorizedPage = lazy(() =>
  import("./components/shared/NotAuthorizedPage")
);
const ManageUsers = lazy(() => import("./components/shared/ManageUsers"));
const Faqs = lazy(() => import("./components/connect/faqs"));
const Forms = lazy(() => import("./components/connect/forms"));
const ViewAccounts = lazy(() => import("./components/connect/ViewAccounts"));
const ContactDetails = lazy(() => import("./components/connect/my-details"));
const Documents = lazy(() => import("./components/connect/ClientDocuments"));
const IntroducerHome = lazy(() =>
  import("./components/introducer/IntroducerHome")
);
const Applications = lazy(() =>
  import("./components/introducer/IntroducerApplications")
);
const IntroducerApplicationInformation = lazy(() =>
  import("./components/introducer/ApplicationInformation")
);
const Portfolios = lazy(() => import("./components/introducer/Portfolios"));
const Resources = lazy(() => import("./components/introducer/Resources"));
const ViewPortfolio = lazy(() =>
  import("./components/introducer/ViewPortfolioAccount")
);
const LearningHub = lazy(() => import("./components/introducer/LearningHub"));
const LearningAcademy = lazy(() =>
  import("./components/introducer/LearningAcademy")
);
const SolicitorApplications = lazy(() =>
  import("./components/solicitor/SolicitorApplications")
);
const SolicitorApplicationInformation = lazy(() =>
  import("./components/solicitor/ApplicationInformation")
);

const SolicitorDischargeInformation = lazy(() =>
  import("./components/solicitor/Discharge/DischargeInformation")
);

const SolicitorDischarges = lazy(() =>
  import("./components/solicitor/SolicitorDischarges")
);
const ContactUs = lazy(() => import("./components/connect/ContactUs"));
const BenefitsRequest = lazy(() => import("./components/shared/Benefits"));
const ClientStatementPreferences = lazy(() =>
  import("./components/connect/ClientStatementPreferences")
);

const renderApp = () => {
  return ReactDOM.render(
    <React.StrictMode>
      <Router>
        <Suspense fallback={<LoaderBar />}>
          <Routes>
            <Route path="/" element={<App />}>
              <Route
                path="/"
                element={
                  UserService.isIntroducer() ||
                  UserService.isBDM() ||
                  UserService._kc.realm.includes("Introducer_Connect") ? (
                    UserService.isClientServices() &&
                    UserService._kc.realm.includes("Introducer_Connect") ? (
                      <ManageUsers />
                    ) : UserService._kc.tokenParsed.preferred_username ===
                        "stieg_larsson_test" || UserService.isAggregator() ? (
                      <LearningHub />
                    ) : (
                      <IntroducerHome />
                    )
                  ) : UserService.isSolicitor() ? (
                    <SolicitorApplications />
                  ) : (
                    <Home />
                  )
                }
              />

              <Route path="not-authorized" element={<NotAuthorizedPage />} />
              <Route path="*" element={<NotFoundPage />} />

              <Route
                path="applications"
                element={
                  UserService.isIntroducer() || UserService.isBDM() ? (
                    <AuthenticatedRoute>
                      <Applications />
                    </AuthenticatedRoute>
                  ) : (
                    UserService.isSolicitor() && <SolicitorApplications />
                  )
                }
              />
              <Route
                path="discharges"
                element={UserService.isSolicitor() && <SolicitorDischarges />}
              />

              <Route
                path="application"
                element={
                  UserService.isIntroducer() || UserService.isBDM() ? (
                    <AuthenticatedRoute>
                      <IntroducerApplicationInformation />
                    </AuthenticatedRoute>
                  ) : (
                    UserService.isSolicitor() && (
                      <SolicitorApplicationInformation />
                    )
                  )
                }
              />
              <Route
                path="discharges/dischargeapplication/:loanNumber/:salesforceId"
                element={
                  UserService.isSolicitor() && <SolicitorDischargeInformation />
                }
              />

              {/* RedZed Connect Menu Items */}
              <Route
                path="forms"
                element={
                  <GuardedRoute isClient={true}>
                    <Forms />
                  </GuardedRoute>
                }
              />

              <Route path="documents" element={<Documents />} />

              <Route
                path="faqs"
                element={
                  <GuardedRoute isClient={true}>
                    <Faqs />
                  </GuardedRoute>
                }
              />
              <Route
                path="accounts"
                element={
                  <GuardedRoute isClient={true}>
                    <ViewAccounts />
                  </GuardedRoute>
                }
              />
              <Route path="benefits" element={<BenefitsRequest />} />
              <Route path="contact-us" element={<ContactUs />} />
              {/* Introducer Connect Menu Items */}

              <Route
                path="portfolios"
                element={
                  <AuthenticatedRoute>
                    <Portfolios />
                  </AuthenticatedRoute>
                }
              />
              <Route
                path="portfolio-account"
                element={
                  <AuthenticatedRoute>
                    {(UserService.isIntroducer() || UserService.isBDM()) && (
                      <ViewPortfolio />
                    )}
                  </AuthenticatedRoute>
                }
              />
              <Route
                path="resources"
                element={
                  <GuardedRoute isClient={false}>
                    <Resources />
                  </GuardedRoute>
                }
              />

              <Route
                path="learning-hub"
                element={
                  <GuardedRoute isClient={false}>
                    <LearningHub />
                  </GuardedRoute>
                }
              />
              <Route path="academy" element={<LearningAcademy />} />

              {/* Solicitor Connect Menu Items */}

              {/* Settings Dropdown Menu Items */}
              <Route
                path="my-details"
                element={
                  <GuardedRoute isClient={true}>
                    <ContactDetails />
                  </GuardedRoute>
                }
              />

              <Route path="manage-users" element={<ManageUsers />} />
              <Route
                path="statement-preferences"
                element={
                  <GuardedRoute isClient={true}>
                    <ClientStatementPreferences />
                  </GuardedRoute>
                }
              />
              <Route
                path="account-settings"
                element={
                  <GuardedRoute isClient={true}>
                    <OffsetAccountSettings />
                  </GuardedRoute>
                }
              />
              {/* <Route path="edit-user" element={
                <GuardedRoute isClient={false}>
                  <EditUser />
                </GuardedRoute>
              }/> */}
              <Route path="change-password" element={<JoinForm />} />
            </Route>
          </Routes>
        </Suspense>
      </Router>
    </React.StrictMode>,
    document.getElementById("root")
  );
};

UserService.initKeycloak(renderApp);
HttpService.configure();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
