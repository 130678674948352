import React, { ReactNode, useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import UserService from "../../services/UserService";

const exemptedRoutes = [
  '/404',
]

export function Timeout({ children }: { children: ReactNode }) {
  const navigate = useNavigate();
  const location = useLocation();
  const timeoutRef = useRef();
  const [showTimeout, setShowTimeout] = useState(false)

  const handleLogout = () => {
    sessionStorage.clear();
    UserService.doLogout();
  }

  const handleWindowEvents = () => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }

    const exp = UserService._kc?.idTokenParsed?.exp;

    if (exp) {
      const currentTimeInSeconds = Math.floor(Date.now() / 1000);
      const timeoutDuration = (exp - currentTimeInSeconds) * 1000;

      if (timeoutDuration > 0) {
        timeoutRef.current = window.setTimeout(() => {
          setShowTimeout(true);
        }, timeoutDuration);
      } else {
        // Token has already expired
        handleLogout();
      }
    } else {
      console.error('Token expiration information is not available.');
      // Optionally, handle this case (e.g., force logout or set a default timeout)
      handleLogout();
    }
  };

  useEffect(() => {
    if (exemptedRoutes.includes(location.pathname)) return;

    window.addEventListener('mousemove', handleWindowEvents);
    window.addEventListener('keydown', handleWindowEvents);
    window.addEventListener('click', handleWindowEvents);
    window.addEventListener('scroll', handleWindowEvents);

    handleWindowEvents();

    return () => {
      window.removeEventListener('mousemove', handleWindowEvents);
      window.removeEventListener('keydown', handleWindowEvents);
      window.removeEventListener('click', handleWindowEvents);
      window.removeEventListener('scroll', handleWindowEvents);
    }
  }, [navigate, location.pathname])

  if (!showTimeout) {
    return children;
  } else {
    return (
      <>
        <div className="session-expired-overlay"></div>
        <div className="session-expired-warning-wrapper text-center">
          <h5>Session Expired</h5>
          <p>This session has been closed for your security. <br />Please login again.</p>
          <button type="button" className="btn px-6 py-2.5 rounded transition duration-150 ease-in-out" onClick={handleLogout}>Login</button>
        </div>
      </>
    )
  }
}