// import config from "../config.json";
import axios from "axios";
import UserService from "./UserService";
import { showError } from "../toast";

const ApiEndpoint = process.env.REACT_APP_API_URI;

const HttpMethods = {
  GET: "GET",
  PUT: "PUT",
  POST: "POST",
  DELETE: "DELETE",
};

const _axios = axios.create();

const configure = () => {
  _axios.interceptors.request.use((config) => {
    if (UserService.isLoggedIn()) {
      const cb = () => {
        config.headers.Authorization = `Bearer ${UserService.getToken()}`;
        return Promise.resolve(config);
      };
      return UserService.updateToken(cb);
    }
  });
};

const getAxiosClient = () => _axios;

const HttpService = {
  HttpMethods,
  configure,
  getAxiosClient,
  get,
  put,
  post,
  logMessage,
  downloadFile,
  downloadSettlementFile,
  // downloadFilePost
};

async function logError(response) {
  await fetch(
    ApiEndpoint +
      `Log?keycloakID=${UserService._kc.tokenParsed.preferred_username}&message=ERROR - HTTP Response Code: ${response?.status}, URL: ${response?.url}`,
    {
      method: "POST",
      headers: {
        Authorization: ("Authorization", "Bearer " + UserService.getToken()),
        Accept: "application/json",
        "Content-Type": "application/json;",
      },
    }
  );
}

async function get(urlSuffix) {
  let response;

  try {
    response = await fetch(ApiEndpoint + urlSuffix, {
      method: HttpMethods.GET,
      headers: {
        Authorization: ("Authorization", "Bearer " + UserService.getToken()),
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    });
  } catch (error) {
    console.log("There was an error", error);
  }

  if (response?.ok) {
    const json = response.json();
    return json;
  } else {
    logError(response);
  }
}

async function put(urlSuffix) {
  let response;

  try {
    response = await fetch(ApiEndpoint + urlSuffix, {
      method: "PUT",
      headers: {
        Authorization: ("Authorization", "Bearer " + UserService.getToken()),
        Accept: "application/json",
        "Content-Type": "application/json;",
      },
    });
  } catch (error) {
    console.log("There was an error", error);
  }

  if (response?.ok) {
    const json = response.json();
    return json;
  } else {
    logError(response);
  }
}

async function post(urlSuffix, bodyVal) {
  let response;

  try {
    response = await fetch(ApiEndpoint + urlSuffix, {
      method: "POST",
      headers: {
        Authorization: ("Authorization", "Bearer " + UserService.getToken()),
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: bodyVal,
    });
  } catch (error) {
    console.log("There was an error", error);
    // console.log('There was an error', error);
  }

  if (response?.ok) {
    const json = response.json();
    return json;
  } else {
    logError(response);
  }
}

function logMessage(id, message) {
  fetch(ApiEndpoint + `Log?keycloakID=${id}&message=${message}`, {
    method: "POST",
    headers: {
      Authorization: ("Authorization", "Bearer " + UserService.getToken()),
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  })
    // .then((response) => response.json())
    .then((response) => {
      return response;
    })
    .catch((error) => console.log(error));
}

function downloadSettlementFile(urlSuffix, filename, event) {
  fetch(ApiEndpoint + urlSuffix, {
    method: HttpMethods.GET,
    headers: {
      Authorization: ("Authorization", "Bearer " + UserService.getToken()),
    },
  })
    .then((res) => res.blob())
    .then((blob) => {
      if (blob.type !== "application/pdf") {
        const errorBox = document.getElementById("download-error-box");
        errorBox.classList.add("show-error-box");
        event.target.setAttribute("disabled", "disabled");
      } else {
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", filename);
        document.body.appendChild(link);
        link.click();
        URL.revokeObjectURL(url);
        document.body.removeChild(link);
      }
    })
    .then(() => {
      event.target.classList.remove("downloading");
    })
    .catch((error) =>
      showError(error, {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      })
    );
}

function downloadFile(urlSuffix, filename, event) {
  fetch(ApiEndpoint + urlSuffix, {
    method: HttpMethods.GET,
    headers: {
      Authorization: ("Authorization", "Bearer " + UserService.getToken()),
    },
  })
    .then((res) => res.blob())
    .then((blob) => {
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", filename);
      document.body.appendChild(link);
      link.click();
      URL.revokeObjectURL(url);
      document.body.removeChild(link);
    })
    .then(() => {
      event.target.classList.remove("downloading");
    })
    .catch((error) =>
      showError(error, {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      })
    );
}

// function downloadFilePost(urlSuffix, bodyVal, fileName, event) {
//   fetch(ApiEndpoint + urlSuffix, {
//     method: HttpMethods.POST,
//     headers: {
//       Authorization: ("Authorization", "Bearer " + UserService.getToken()),
//       Accept: "application/json",
//       "Content-Type": "application/json",
//     },
//     body: bodyVal,
//   })
//     .then((res) => res.blob())
//     .then((blob) => {
//       const url = window.URL.createObjectURL(blob);
//       const link = document.createElement("a");
//       link.href = url;
//       link.setAttribute("download", fileName);
//       document.body.appendChild(link);
//       link.click();
//       URL.revokeObjectURL(url);
//       document.body.removeChild(link);
//     })
//     .then(() => {
//       event.target.classList.remove('downloading');
//     })
//     .catch((error) =>
//       showError(error, {
//         position: "bottom-right",
//         autoClose: 5000,
//         hideProgressBar: false,
//         closeOnClick: true,
//         pauseOnHover: true,
//         draggable: true,
//         progress: undefined,
//       })
//     );
// }

export default HttpService;
