import React from "react"
import UserService from "../../services/UserService"
import { NavLink } from "react-router-dom"

export default function SettingsButton() {
  return (
    <div className="settings-dropdown relative w-1/2 lg:w-1/3 mx-1 md:mx-2 max-w-[160px]" data-te-dropdown-ref>
      <button
        className="settings-btn dropdown-toggle items-center w-full p-2 rounded justify-center flex flex-wrap font-semibold"
        type="button"
        id="settingsDropdown"
        data-te-dropdown-toggle-ref
        aria-expanded="false"
      >
        Settings
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-gear ml-2" viewBox="0 0 16 16">
          <path d="M8 4.754a3.246 3.246 0 1 0 0 6.492 3.246 3.246 0 0 0 0-6.492zM5.754 8a2.246 2.246 0 1 1 4.492 0 2.246 2.246 0 0 1-4.492 0z" />
          <path d="M9.796 1.343c-.527-1.79-3.065-1.79-3.592 0l-.094.319a.873.873 0 0 1-1.255.52l-.292-.16c-1.64-.892-3.433.902-2.54 2.541l.159.292a.873.873 0 0 1-.52 1.255l-.319.094c-1.79.527-1.79 3.065 0 3.592l.319.094a.873.873 0 0 1 .52 1.255l-.16.292c-.892 1.64.901 3.434 2.541 2.54l.292-.159a.873.873 0 0 1 1.255.52l.094.319c.527 1.79 3.065 1.79 3.592 0l.094-.319a.873.873 0 0 1 1.255-.52l.292.16c1.64.893 3.434-.902 2.54-2.541l-.159-.292a.873.873 0 0 1 .52-1.255l.319-.094c1.79-.527 1.79-3.065 0-3.592l-.319-.094a.873.873 0 0 1-.52-1.255l.16-.292c.893-1.64-.902-3.433-2.541-2.54l-.292.159a.873.873 0 0 1-1.255-.52l-.094-.319zm-2.633.283c.246-.835 1.428-.835 1.674 0l.094.319a1.873 1.873 0 0 0 2.693 1.115l.291-.16c.764-.415 1.6.42 1.184 1.185l-.159.292a1.873 1.873 0 0 0 1.116 2.692l.318.094c.835.246.835 1.428 0 1.674l-.319.094a1.873 1.873 0 0 0-1.115 2.693l.16.291c.415.764-.42 1.6-1.185 1.184l-.291-.159a1.873 1.873 0 0 0-2.693 1.116l-.094.318c-.246.835-1.428.835-1.674 0l-.094-.319a1.873 1.873 0 0 0-2.692-1.115l-.292.16c-.764.415-1.6-.42-1.184-1.185l.159-.291A1.873 1.873 0 0 0 1.945 8.93l-.319-.094c-.835-.246-.835-1.428 0-1.674l.319-.094A1.873 1.873 0 0 0 3.06 4.377l-.16-.292c-.415-.764.42-1.6 1.185-1.184l.292.159a1.873 1.873 0 0 0 2.692-1.115l.094-.319z" />
        </svg>
      </button>
      <div
        className="absolute z-[1000] float-left m-0 hidden min-w-max list-none overflow-hidden rounded-lg border-none bg-white bg-clip-padding text-left text-base shadow-lg dark:bg-neutral-700 [&[data-te-dropdown-show]]:block"
        aria-labelledby="settingsDropdown"
        data-te-dropdown-menu-ref
      >
        {UserService.isCustomer() && !UserService.isClientServices() &&
          <div className="nav-item py-2 px-4" data-te-dropdown-item-ref data-te-offcanvas-dismiss>
            <NavLink className="nav-link dropdown-item" to="/my-details">
              My Contact Details
            </NavLink>
          </div>
        }
        {(UserService.isCustomer() || UserService.isClientServices()) &&
          <div className="nav-item py-2 px-4" data-te-dropdown-item-ref data-te-offcanvas-dismiss>
            <NavLink className="nav-link dropdown-item" to="/statement-preferences">
              Statement Preferences
            </NavLink>
          </div>
        }
        {(UserService.isCustomer() || UserService.isClientServices()) &&
          <div className="nav-item py-2 px-4" data-te-dropdown-item-ref data-te-offcanvas-dismiss>
            <NavLink className="nav-link dropdown-item" to="/account-settings">
              Account Settings
            </NavLink>
          </div>
        }

        {(UserService.isMasterSolicitor() || UserService.isSuperMasterIntroducer()) &&
          <div className="nav-item py-2 px-4" data-te-dropdown-item-ref data-te-offcanvas-dismiss>
            <NavLink className="nav-link dropdown-item" to="/manage-users">
              Manage Users
            </NavLink>
          </div>
        }
        <div className="nav-item py-2 px-4" data-te-dropdown-item-ref data-te-offcanvas-dismiss>
          <NavLink className="nav-link dropdown-item" to="/change-password">
            Change Password
          </NavLink>
        </div>
      </div>
    </div>
  )
}